// src/HierarchySelection.js
import React, { useState, useRef, useEffect } from "react";
import AnimatedBackground from "./components/AnimatedBackground";
import LoadingSequence from "./components/LoadingSequence";
import { ChevronRight, CircleHelp, RefreshCw } from "lucide-react";
import { FileText, LayersIcon, BrainCircuit } from "lucide-react";
import { PlayCircle } from 'lucide-react';
import Player from '@vimeo/player';
import {
  HIERARCHY_SELECTION_URL,
  HIERARCHY_SELECTION_PROMPTID,
  HEADER_CLIENT_KEY,
} from "./utils/apiManager";
import useAccessToken from "./useAccessToken";
import {
  GitBranch,
  AlertCircle,
  Zap,
  AlertTriangle,
  CircuitBoard, // Changed from Circuit
  Languages,
  ScanLine,
  ListFilter,
  Network, // Changed from Workflow
  ShieldCheck,
  Scale,
} from "lucide-react";
import AnimatedBorder from "./components/AnimatedBorder";
import TryDemoButton from "./components/TryDemoButton";
import ThreeStepProgress from "./components/ThreeStepProgress";
import TypeWriter from "./components/TypeWriter";
import CategoryModal from './components/CategoryModal';
import {
  INJURY_CATEGORIES,
} from './utils/constants';
import { decryptResponse } from './utils/decryption';

const HierarchySelection = () => {
  const demoRef = useRef(null);
  // Add new state management
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [aiOutput, setAiOutput] = useState(null);

  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const accessToken = useAccessToken();

  const resetState = () => {
    setCurrentStep(1);
    setDescription("");
    setIsLoading(false);
    setAiOutput(null);
  };
  const videoContainerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePlayVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play().then(() => {
        setIsVideoPlaying(true);
      }).catch(error => {
        //console.error("Error playing video:", error);
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let player = null;

    const initPlayer = async () => {
      if (videoContainerRef.current) {
        const options = {
          url: 'https://vimeo.com/1029823665/09232f4715',
          autoplay: false,
          loop: false,
          controls: true,
          responsive: true,
          muted: false,
          title: false,
          byline: false,
          portrait: false
        };

        try {
          player = new Player(videoContainerRef.current, options);
          playerInstanceRef.current = player;

          await player.ready();
          await player.setVolume(1);
        } catch (error) {
          console.log("Player initialization error:", error);
        }
      }
    };

    initPlayer();

    return () => {
      if (player) {
        player.unload().catch(console.log);  // Proper cleanup
        playerInstanceRef.current = null;
      }
    };
  }, []);

  const analyzeDescription = async (text) => {
    setIsLoading(true);
    try {
      const requestAiServiceBody = {
        promptId: HIERARCHY_SELECTION_PROMPTID,
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: text
          }
        ]
      };
      const apiPromise = fetch(HIERARCHY_SELECTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          "x-client-key": HEADER_CLIENT_KEY,
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestAiServiceBody),
      }).then((res) => res.json());

      const [data] = await Promise.all([
        apiPromise,
        new Promise((resolve) => setTimeout(resolve, 12000)),
      ]);

      const decryptedResponse = decryptResponse(data.result);

      let module_response = decryptedResponse;

      module_response = module_response.replace("```json\n", "").replace("\n```", "");
      module_response = JSON.parse(module_response);
      // console.log(module_response);
      // Check if Possible options is empty
      if (!module_response["Possible options"] || module_response["Possible options"].length === 0) {
        setAiOutput([{
          incidentType: "Invalid",
          cause: "Invalid Description",
          subCase: "The incident description is not valid. Please enter a valid description",
          liability: "Invalid"
        }]);
        setCurrentStep(2);
        return;
      }

      // let assessments = module_response["assessment %"];
      // let max_assessment = Math.max(...Object.values(assessments));
      // let threshold = 10;

      // let best_option_keys = Object.keys(assessments).filter(option => 
      //   max_assessment - assessments[option] <= threshold
      // );

      // let best_options = [];
      // module_response["Possible options"].forEach(option_dict => {
      //   best_option_keys.forEach(key => {
      //     if (key in option_dict) {
      //       best_options.push(option_dict[key]);
      //     }
      //   });
      // });

      // let bestOptions = [];
      // best_options.forEach(option => {
      //   bestOptions.push(option);
      // });

      // const processedOutput = bestOptions.map((item) => {
      //   const parts = item.split(" -> ");
      //   return {
      //     incidentType: parts.length > 2 ? parts[0] : parts[0],
      //     cause: parts.length > 2 ? parts[1] : parts[0],
      //     subCase: parts[parts.length - 1],
      //     liability: "Fault/Non-Fault/Partial",
      //   };
      // });

      // let assessments = module_response["assessment %"];
      // let scores = Object.values(assessments);
      // let max_assessment = Math.max(...scores);

      // // Get actual second highest (not just any other score)
      // let secondHighestScore = Math.max(...scores.filter(score => score < max_assessment));

      // let threshold = 10;

      // // Modified condition to properly check threshold
      // let best_option_keys = Object.keys(assessments).filter(option => {
      //   let score = assessments[option];
      //   return score === max_assessment || // Highest scores
      //          (score === secondHighestScore && // Second highest scores
      //           (max_assessment - secondHighestScore) <= threshold); // Within threshold
      // });

      // let best_options = [];
      // let best_scores = [];
      // module_response["Possible options"].forEach(option_dict => {
      //   best_option_keys.forEach(key => {
      //     if (key in option_dict) {
      //       best_options.push(option_dict[key]);
      //       best_scores.push(assessments[key]);
      //     }
      //   });
      // });

      let assessments = module_response["assessment %"];
      let scores = [...new Set(Object.values(assessments))].sort((a, b) => b - a);
      let max_assessment = scores[0];
      let threshold = 10;

      let best_option_keys = [];
      // First get the best non-unlisted options
      module_response["Possible options"].forEach((option_dict, index) => {
        const key = `option ${index + 1}`;
        const score = assessments[key];
        const option = option_dict[key];

        // Check if it's not an unlisted incident and meets threshold
        if (!option.includes("Unlisted Incident") &&
          (score === max_assessment || max_assessment - score <= threshold)) {
          best_option_keys.push(key);
        }
      });

      let best_options = [];
      let best_scores = [];
      module_response["Possible options"].forEach(option_dict => {
        best_option_keys.forEach(key => {
          if (key in option_dict) {
            best_options.push(option_dict[key]);
            best_scores.push(assessments[key]);
          }
        });
      });

      const processedOutput = best_options.map((item, index) => {
        const parts = item.split(" -> ");
        return {
          incidentType: parts.length > 2 ? parts[0] : parts[0],
          cause: parts.length > 2 ? parts[1] : parts[0],
          subCase: parts[parts.length - 1],
          liability: "Fault/Non-Fault/Partial",
          confidence: best_scores[index]
        };
      });

      setAiOutput(processedOutput);
      setCurrentStep(2);
    } catch (error) {
      //console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  // Modify the textarea onChange handler
  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    const wordCount = countWords(text);

    if (wordCount <= 250) {
      setDescription(text);
    }
  };
  // Add the renderStep function
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            {/* Enhanced benefit message */}
            <div className="bg-black/20 backdrop-blur-sm rounded-2xl p-4 mb-6 border border-[#00D05E]/20">
              <div className="flex items-center gap-3">
                <div>
                  <Zap className="w-5 h-5 text-[#F93DCD]" />
                </div>
                <p className="text-[#ACACAC] leading-relaxed text-lg">
                  This module helps you and your claimants by automatically identifying relevant categories based on the claim description ensuring you receive more accurate claim data.
                </p>
              </div>
            </div>
            {!isLoading && (
              <p className="text-white mb-3 flex justify-start items-center">
                <CircleHelp size={24} color="#00D05E" />
                <span className="ml-2">Describe your Incident</span>
              </p>
            )}
            {isLoading && <LoadingSequence />}
            <div className="bg-black/40 backdrop-blur-sm rounded-[10px] border border-gray-800 overflow-hidden">
              <div className="space-y-4 p-3">
                <textarea
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="I was cycling and my wheel broke causing me to fell and break my wrist."
                  className="w-full h-32 bg-transparent rounded-lg text-white focus:outline-none resize-none placeholder-gray-500"
                />
                <div className="text-sm text-gray-400 mt-2">
                  {countWords(description)}/250 words
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => analyzeDescription(description)}
                disabled={!description.trim() || isLoading}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50"
              >
                Continue
              </button>
            </div>
          </>
        );

      case 2:
        // Check for invalid description
        if (aiOutput.length === 1 && aiOutput[0].subCase === "The incident description is not valid. Please enter a valid description") {
          return (
            <div className="space-y-8">
              <AnimatedBorder>
                <div className="bg-black/95 p-4 md:p-6 rounded-3xl">
                  <div className="flex items-start gap-4">
                    <div className="bg-[#F93DCD]/10 p-1.5 md:p-2 rounded-full">
                      <AlertCircle className="text-[#F93DCD] w-5 h-5 md:w-6 md:h-6" />
                    </div>
                    <div>
                      <h3 className="text-white text-base md:text-lg font-semibold mb-2">
                        Invalid Description
                      </h3>
                      <p className="text-[#ACACAC] text-sm md:text-base leading-relaxed">
                        It looks like the incident description isn't quite right. Could you please ensure it is a valid injury description?
                      </p>
                    </div>
                  </div>
                </div>
              </AnimatedBorder>
              <div className="flex justify-end mt-6">
                <button
                  onClick={() => setCurrentStep(1)}
                  className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
                >
                  Try Again
                </button>
              </div>
            </div>
          );
        }
        // Check for unlisted incident
        else if (aiOutput.every(output =>
          output.cause === "Unlisted Incident" ||
          output.subCase === "Unlisted Incident"
        )) {
          return (
            <div className="space-y-8">
              <AnimatedBorder>
                <div className="bg-black/95 p-4 md:p-6 rounded-3xl">
                  <div className="flex items-start gap-4">
                    <div className="bg-[#F93DCD]/10 p-1.5 md:p-2 rounded-full">
                      <AlertCircle className="text-[#F93DCD] w-5 h-5 md:w-6 md:h-6" />
                    </div>
                    <div>
                      <h3 className="text-white text-base md:text-lg font-semibold mb-2">
                        Limited Categories For Demo
                      </h3>
                      <p className="text-[#ACACAC] text-sm md:text-base leading-relaxed">
                        For this demo we have selected 73 categories. Please refer to our list{' '}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsModalOpen(true);
                          }}
                          className="text-[#00D05E] hover:text-[#00D05E]/80 underline"
                        >
                          here
                        </a>.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimatedBorder>
              <div className="flex justify-end mt-6">
                <button
                  onClick={() => setCurrentStep(3)}
                  className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
                >
                  Continue
                </button>
              </div>
            </div>
          );
        }

        //           // Regular case with valid data
        //           return (
        // <div className="space-y-8">
        //   {/* Make it single column on mobile, but 3 columns on desktop */}
        //   <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
        //     {/* Incident Type Card */}
        //     <AnimatedBorder>
        //       <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
        //         <div className="flex items-start justify-between mb-4">
        //           <h3 className="text-[#ACACAC] text-sm md:text-base">
        //             Incident Type
        //           </h3>
        //           <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
        //             <AlertCircle className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
        //           </div>
        //         </div>
        //         <div className="space-y-2">
        //           {[...new Set(aiOutput.map(output => output.incidentType))].map((type, index) => (
        //             <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
        //               <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
        //                 {type}
        //               </p>
        //               {index > 0 && (
        //                 <span className="text-[#ACACAC] text-xs">Alternative Match</span>
        //               )}
        //             </div>
        //           ))}
        //         </div>
        //       </div>
        //     </AnimatedBorder>

        //     {/* Cause Card */}
        //     <AnimatedBorder>
        //       <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
        //         <div className="flex items-start justify-between mb-4">
        //           <h3 className="text-[#ACACAC] text-sm md:text-base">
        //             Cause
        //           </h3>
        //           <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
        //             <GitBranch className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
        //           </div>
        //         </div>
        //         <div className="space-y-2">
        //           {[...new Set(aiOutput.map(output => output.cause))].map((cause, index) => (
        //             <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
        //               <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
        //                 {cause}
        //               </p>
        //               {index > 0 && (
        //                 <span className="text-[#ACACAC] text-xs">Alternative Match</span>
        //               )}
        //             </div>
        //           ))}
        //         </div>
        //       </div>
        //     </AnimatedBorder>

        //     {/* Sub-Case Card */}
        //     <AnimatedBorder>
        //       <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
        //         <div className="flex items-start justify-between mb-4">
        //           <h3 className="text-[#ACACAC] text-sm md:text-base">
        //             Sub-Case
        //           </h3>
        //           <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
        //             <ListFilter className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
        //           </div>
        //         </div>
        //         <div className="space-y-2">
        //           {[...new Set(aiOutput.map(output => output.subCase))].map((subCase, index) => (
        //             <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
        //               <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
        //                 {subCase}
        //               </p>
        //               {index > 0 && (
        //                 <span className="text-[#ACACAC] text-xs">Alternative Match</span>
        //               )}
        //             </div>
        //           ))}
        //         </div>
        //       </div>
        //     </AnimatedBorder>
        //   </div>

        //   <div className="flex justify-end mt-6">
        //     <button
        //       onClick={() => setCurrentStep(3)}
        //       className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
        //     >
        //       Continue
        //     </button>
        //   </div>
        // </div>
        //           );
        return (
          <div className="space-y-8">
            <AnimatedBorder>
              <div className="bg-black/95 p-4 md:p-6 rounded-3xl">
                <div className="relative">
                  <AnimatedBackground />
                  <table className="w-full border-collapse relative z-10">
                    {/* Headers - Hide on mobile, show on desktop */}
                    <thead className="hidden md:table-header-group">
                      <tr className="border-b border-gray-800">
                        <th className="p-4 text-left text-[#ACACAC]">
                          <div className="flex items-center gap-2">
                            <AlertCircle className="text-[#00D05E] w-4 h-4" />
                            Incident Type
                          </div>
                        </th>
                        <th className="p-4 text-left text-[#ACACAC]">
                          <div className="flex items-center gap-2">
                            <GitBranch className="text-[#00D05E] w-4 h-4" />
                            Cause
                          </div>
                        </th>
                        <th className="p-4 text-left text-[#ACACAC]">
                          <div className="flex items-center gap-2">
                            <ListFilter className="text-[#00D05E] w-4 h-4" />
                            Sub-Case
                          </div>
                        </th>
                        <th className="p-4 text-right text-[#ACACAC]">
                          <div className="flex items-center justify-end gap-2">
                            <CircleHelp className="text-[#00D05E] w-4 h-4" />
                            Assessment %
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-800">
                      {aiOutput.map((output, index) => (
                        <tr key={index} className="block md:table-row">
                          <td className="block md:table-cell p-4 text-[#00D05E]">
                            {/* Mobile header */}
                            <div className="flex items-center gap-2 text-[#ACACAC] text-sm mb-1 md:hidden">
                              <AlertCircle className="text-[#00D05E] w-4 h-4" />
                              Incident Type
                            </div>
                            {/* Content */}
                            <div className="text-[#00D05E]">
                              {output.incidentType}
                            </div>
                          </td>
                          <td className="block md:table-cell p-4">
                            {/* Mobile header */}
                            <div className="flex items-center gap-2 text-[#ACACAC] text-sm mb-1 md:hidden">
                              <GitBranch className="text-[#00D05E] w-4 h-4" />
                              Cause
                            </div>
                            {/* Content */}
                            <div className="text-[#00D05E]">{output.cause}</div>
                          </td>
                          <td className="block md:table-cell p-4">
                            {/* Mobile header */}
                            <div className="flex items-center gap-2 text-[#ACACAC] text-sm mb-1 md:hidden">
                              <ListFilter className="text-[#00D05E] w-4 h-4" />
                              Sub-Case
                            </div>
                            {/* Content */}
                            <div className="text-[#00D05E]">{output.subCase}</div>
                          </td>
                          <td className="block md:table-cell p-4 md:text-right">
                            {/* Mobile header */}
                            <div className="flex items-center gap-2 text-[#ACACAC] text-sm mb-1 md:hidden">
                              <CircleHelp className="text-[#00D05E] w-4 h-4" />
                              Match Type
                            </div>
                            {/* Content */}
                            <div className="inline-flex items-center gap-2">
                              <span className="text-[#00D05E]">
                                {(() => {
                                  const currentScore = output.confidence;
                                  const maxScore = Math.max(...aiOutput.map(o => o.confidence));
                                  const allScores = aiOutput.map(o => o.confidence);

                                  if (aiOutput.length === 1) return "Very High";

                                  if (maxScore <= 25) return "Low";

                                  const gapFromMax = maxScore - currentScore;

                                  // Handle equal high scores first
                                  if (gapFromMax === 0) {
                                    // If multiple equal highest scores
                                    if (allScores.filter(score => score === maxScore).length > 1) {
                                      if (maxScore >= 40) return "High";
                                      return "Medium";
                                    }
                                    // Single highest score
                                    return "High";
                                  }

                                  // Handle differences
                                  if (maxScore >= 40) {
                                    // For cases like {40,30,20,10}
                                    if (gapFromMax <= 10) return "Medium";
                                    return "Low";
                                  } else {
                                    // For cases like {30,20,30,20}
                                    if (gapFromMax < 10) return "Medium";
                                    return "Low";
                                  }
                                })()}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </AnimatedBorder>

            <div className="flex justify-end mt-6">
              <button
                onClick={() => setCurrentStep(3)}
                className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
              >
                Continue
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6 text-white">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <FileText className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Universal Application" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Applicable to any free-text description across your entire
                    system"
                      delay={10}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <LayersIcon className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Seamless Category Management" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Enables adding required categories without affecting the
                    customer experience"
                      delay={20}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <BrainCircuit className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Simplified Decision Making" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Reduces complexity by presenting only the most relevant
                    options"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
            </div>

            <div className="flex justify-end">
              <button
                onClick={resetState}
                disabled={!description.trim() || isLoading}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50 flex justify-center items-center"
              >
                <RefreshCw size={18} />
                <span className="ml-2">Try Again</span>
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative py-32">
      <AnimatedBackground />
      {/* Hero Section with Video and Info Boxes */}
      <div className="max-w-5xl mx-auto mb-9">
        {/* Title and Video Row */}
        <div className="flex flex-col md:flex-row items-start justify-between gap-8 mb-6">
          <div className="flex-1">
            <h1 className="text-[32px] md:text-[40px] font-['Impact']  text-[#00D05E] mb-4">
              Welcome to Category Selection Module
            </h1>
            <h1 className="text-2xl font-semibold text-[#F93DCD] mb-2">For this demo, we have selected 74 injury categories. You can have as many as you want and this is purely for demonstration.</h1>

            <p className="text-[#ACACAC] leading-relaxed mb-6">
              Let our AI assistant guide you through complex category hierarchies with ease.<span className="text-lg text-[#F93DCD]"> Please play the video to understand how the demo works.</span>
            </p>
            {/* Info Boxes Below Title */}
            <div className="grid grid-cols-1 gap-6">
              <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
                <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                  <GitBranch />
                  <span className="ml-2">Smart Category Navigation</span>
                </h2>
                <p className="leading-[160%] font-light text-[#ACACAC]">
                  The Category Selection module analyses free text to intelligently
                  navigate through complex category hierarchies, acting as a GPS for
                  lengthy option lists. It streamlines user navigation by presenting
                  only the most relevant choices.
                </p>
              </section>

              <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
                <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                  <AlertTriangle />
                  <span className="ml-2">Current Challenges</span>
                </h2>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">User confusion with lengthy option lists</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Multiple incorrect selections</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Random option selection to progress</span>
                </p>
              </section>
            </div>
          </div>

          {/* Video Avatar */}
          <div className="w-full md:w-[320px] shrink-0">
            <div className="aspect-[9/16] relative rounded-xl border border-gray-800 overflow-hidden bg-black/40">
              <div
                ref={videoContainerRef}
                className="w-full h-full z-10 relative pointer-events-auto"
                style={{
                  position: 'relative',
                  zIndex: 50
                }}
              />

              {/* Play Overlay */}
              {!isVideoPlaying && (
                <div
                  onClick={handlePlayVideo}
                  className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 cursor-pointer z-[60] transition-opacity hover:bg-black/40"
                >
                  <div className="w-16 h-16 rounded-full bg-[#00D05E]/20 flex items-center justify-center backdrop-blur-sm border border-[#00D05E]/30 mb-3">
                    <PlayCircle size={40} className="text-[#00D05E]" />
                  </div>
                  <p className="text-white text-center px-4">
                    Click me to understand how the demo works
                  </p>
                </div>
              )}
            </div>
            {/* Demo Button and Animation - Moved here */}
            <div className="mt-8 flex flex-col items-center">
              <div
                className="cursor-pointer mb-4"
                onClick={scrollToDemo}
              >
                <div className="flex flex-col items-center hover:scale-110 transition-transform">
                  <div className="text-[#00D05E] text-2xl font-bold mb-2">Try the Demo Below!</div>
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-[#00D05E]"
                  >
                    <path
                      d="M12 22L12 2M12 22L18 16M12 22L6 16"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="flex justify-center items-center">
      <TryDemoButton onClick={scrollToDemo} />
    </div> */}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mb-9">
          <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
            <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
              <CircuitBoard />
              <span className="ml-2">Technical Capabilities</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Languages />
                  <span className="ml-2">Natural Language</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Process free-text descriptions in user's natural language
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ScanLine />
                  <span className="ml-2">Smart Analysis</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Contextual analysis for precise category matching
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ListFilter />
                  <span className="ml-2">Dynamic Filtering</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Presents only relevant options from the full category list
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Network />
                  <span className="ml-2">Journey Optimisation</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Multiple selections through single text input
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ShieldCheck />
                  <span className="ml-2">Error Prevention</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Reduces incorrect category selections
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Scale />
                  <span className="ml-2">Scalable System</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Adaptable to expanding category lists
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <div className="hidden md:flex justify-center items-center mb-9">
        <TryDemoButton onClick={scrollToDemo} />
      </div> */}
      <section ref={demoRef}>
        {/* Third Section */}
        <div className="max-w-4xl mx-auto text-center mb-9 pt-9">
          <h2 className="text-2xl font-bold text-[#00D05E] mb-2">
            CATEGORY SELECTION DEMO
          </h2>
          {currentStep === 1 && (
            <p className="leading-[160%] font-light text-[#ACACAC]">
              This demo shows {" "}
              <span className="text-[#F93DCD]">Optium</span> automatically
              identifying from   <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
                className="text-[#00D05E] hover:text-[#00D05E]/80 underline"
              >
                74 injury categories
              </a> using just the <span className="text-xl text-[#F93DCD]">injury description. </span>
              Please write a description of an injury incident below and click 'continue' to see Optium
              extract the injury category automatically for you.
            </p>
          )}
          {currentStep === 2 && (
            <p className="leading-[160%] font-light text-[#ACACAC]">
              {aiOutput.length > 1
                ? "Optium has reviewed your description and identified multiple possible options that fit. You can review them below"
                : "Optium has reviewed your description and the outputs can be see below"}
            </p>
          )}
          {currentStep === 3 && (
            <p className="leading-[160%] font-light text-[#ACACAC]">
              What does this mean for you
            </p>
          )}
        </div>
        {/* Forth Section */}
        <div className="max-w-4xl mx-auto text-center mb-9">
          <ThreeStepProgress currentStep={currentStep} />
        </div>
        {/* Fifth Section */}
        <div className="max-w-5xl mx-auto">
          <AnimatedBorder>
            <div className="bg-black backdrop-blur-sm rounded-3xl border border-gray-800 p-6 overflow-hidden">
              {/* Content Card */}
              <AnimatedBackground />
              {renderStep()}
            </div>
          </AnimatedBorder>
        </div>
      </section>
      <CategoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        categories={INJURY_CATEGORIES}
        type="injury"
      />
    </div>
  );
};

export default HierarchySelection;
