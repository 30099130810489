import React, { useState, useRef, useEffect } from "react";
import {
  FileText,
  AlertCircle,
  CheckCircle,
  CircleHelp,
  RefreshCw,
  GitBranch,
  AlertTriangle,
  CircuitBoard,
  Languages,
  Zap,
  ScanLine,
  ListFilter,
  Network,
  ShieldCheck,
  Scale,
  ChevronRight,
} from "lucide-react";
import PolicyCard from "./components/PolicyCard";
import { PlayCircle } from 'lucide-react';
import Player from '@vimeo/player';
import { POLICY_WORDINGS } from './utils/constants';
import ExtractAnimation from "./components/ExtractAnimation";
import AnimatedBackground from "./components/AnimatedBackground";
import AnimatedBorder from "./components/AnimatedBorder";
import TryDemoButton from "./components/TryDemoButton";
import ThreeStepProgress from "./components/ThreeStepProgress";
import TypeWriter from "./components/TypeWriter";
import {
  POLICY_EXTRACTION_URL,
  POLICY_EXTRACTION_PROMPTID_MOTOR,
  HEADER_CLIENT_KEY
} from "./utils/apiManager";
import useAccessToken from "./useAccessToken";
import { decryptResponse } from './utils/decryption';

const PolicyExtraction = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");
  const [isExtracting, setIsExtracting] = useState(false);
  const [policyInfo, setPolicyInfo] = useState(null);
  const demoRef = useRef(null);

  const resetState = () => {
    setCurrentStep(1);
    setDescription("");
    setIsExtracting(false);
    setPolicyInfo(null);
  };
  const accessToken = useAccessToken();
  const videoContainerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play().then(() => {
        setIsVideoPlaying(true);
      }).catch(error => {
        //console.error("Error playing video:", error);
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let player = null;

    const initPlayer = async () => {
      if (videoContainerRef.current) {
        const options = {
          url: 'https://vimeo.com/1029825199/ea69c179a7',
          autoplay: false,
          loop: false,
          controls: true,
          responsive: true,
          muted: false,
          title: false,
          byline: false,
          portrait: false
        };

        try {
          player = new Player(videoContainerRef.current, options);
          playerInstanceRef.current = player;

          await player.ready();
          await player.setVolume(1);
        } catch (error) {
          console.log("Player initialization error:", error);
        }
      }
    };

    initPlayer();

    return () => {
      if (player) {
        player.unload().catch(console.log);
        playerInstanceRef.current = null;
      }
    };
  }, []);

  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const extractPolicyInfo = async () => {
  //   setIsExtracting(true);
  //   try {
  //     const response = await fetch("http://localhost:8000/retrieve_policy", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
  //       },
  //       body: JSON.stringify({
  //         claim_data: {
  //           incident_type: "accident",
  //           incident_location: "Westminster",
  //           claim_narrative: description,
  //         },
  //       }),
  //     });
  //     const data = await response.json();
  //     setPolicyInfo(data.policy_information);

  //     setTimeout(() => {
  //       setIsExtracting(false);
  //       setCurrentStep(2);
  //     }, 2000);
  //   } catch (error) {
  //     //console.error("Error:", error);
  //     setIsExtracting(false);
  //   }
  // };
  const extractPolicyInfo = async () => {
    setIsExtracting(true);
    try {
      // const requestBody = {
      //   insurance_policy: `${POLICY_WORDINGS.MOTOR.policy_text}`,
      //   claim_narrative: description
      // };

      const requestAiServiceBody = {
        promptId: POLICY_EXTRACTION_PROMPTID_MOTOR,
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: description //JSON.stringify(requestBody)
          }
        ]
      };

      const apiPromise = fetch(POLICY_EXTRACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          // -> client key for AI Service
          "x-client-key": HEADER_CLIENT_KEY,
          // bearer token from auth0
          "Authorization": `Bearer ${accessToken}`
        },

        body: JSON.stringify(requestAiServiceBody),
      }
      ).then((res) => res.json());

      // Wait for both the API and the animation sequence
      const [data] = await Promise.all([
        apiPromise,
      ]);

      const decryptedResponse = decryptResponse(data.result);
      let module_response = decryptedResponse;

      module_response = module_response.replace("```json\n", "").replace("\n```", "");
      module_response = JSON.parse(module_response);
      // console.log(module_response);

      const policy_inclusions = module_response["Policy Information (for the specific Incident Type)"]["Policy Inclusions"];
      const policy_exclusions = module_response["Policy Information (for the specific Incident Type)"]["Policy Exclusions"];
      const general_exclusions = module_response["General and Other Exclusions based on the Claim Scenario"]["General Exclusions and Conditions"];
      const other_exclusions = module_response["General and Other Exclusions based on the Claim Scenario"]["Other Exclusions"];

      const formatted_response = {
        policy_information: {
          "Policy Inclusions": policy_inclusions,
          "Policy Exclusions": policy_exclusions,
          "General Exclusions": general_exclusions,
          "Other Exclusions": other_exclusions
        }
      };

      // console.log(formatted_response.policy_information);
      setPolicyInfo(formatted_response.policy_information);

      setTimeout(() => {
        setIsExtracting(false);
        setCurrentStep(2);
      }, 2000);
    } catch (error) {
      //console.error("Error:", error);
      setIsExtracting(false);
    }
  };
  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  // Modify the textarea onChange handler
  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    const wordCount = countWords(text);

    if (wordCount <= 250) {
      setDescription(text);
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            {/* Enhanced benefit message */}
            <div className="bg-black/20 backdrop-blur-sm rounded-2xl p-4 mb-6 border border-[#00D05E]/20">
              <div className="flex items-center gap-3">
                <div>
                  <Zap className="w-5 h-5 text-[#F93DCD]" />
                </div>
                <p className="text-[#ACACAC] leading-relaxed text-lg">
                  This module helps you automatically identify relevant sections of the policy wordings based on the claim, streamlining the review process and saving time for your claim handlers.
                </p>
              </div>
            </div>
            {!isExtracting && (
              <p className="text-white mb-3 flex justify-start items-center">
                <CircleHelp size={24} color="#00D05E" />
                <span className="ml-2">Describe your Incident</span>
              </p>
            )}
            {isExtracting && <ExtractAnimation />}
            <div className="bg-black/40 backdrop-blur-sm rounded-[10px] border border-gray-800 overflow-hidden">
              <div className="space-y-4 p-3">
                <textarea
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="I was in a grocery store and a mob lit my car on fire."
                  className="w-full h-32 bg-transparent rounded-lg text-white focus:outline-none resize-none placeholder-gray-500"
                />
                <div className="text-sm text-gray-400 mt-2">
                  {countWords(description)}/250 words
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={extractPolicyInfo}
                disabled={!description.trim() || isExtracting}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50"
              >
                Extract Policy Information
              </button>
            </div>
          </>
        );

      case 2:
        return (
          <div className="space-y-8">
            {/* Policy Information Display */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                {
                  title: "Policy Inclusions",
                  data: policyInfo?.["Policy Inclusions"] || [],
                },
                {
                  title: "Policy Exclusions",
                  data: policyInfo?.["Policy Exclusions"] || [],
                },
                {
                  title: "General Exclusions",
                  data: policyInfo?.["General Exclusions"] || [],
                },
                {
                  title: "Other Exclusions",
                  data: policyInfo?.["Other Exclusions"] || [],
                },
              ].map((section, index) => (
                <div
                  key={section.title}
                  className="opacity-0 animate-fade-in"
                  style={{ animationDelay: `${index * 200}ms` }}
                >
                  <AnimatedBorder>
                    <div className="bg-black/90 backdrop-blur-sm rounded-3xl border border-gray-800 overflow-hidden">
                      <PolicyCard title={section.title} items={section.data} />
                    </div>
                  </AnimatedBorder>
                </div>
              ))}
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => setCurrentStep(3)}
                className="bg-[#00D05E] text-white px-6 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
              >
                View Capabilities
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6 text-white">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <FileText className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Smart Extraction" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Contextually identifies and extracts relevant policy sections"
                      delay={10}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <AlertCircle className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Quick Review" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Enables rapid assessment of policy coverage and exclusions"
                      delay={20}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <FileText className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Automated Analysis" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Reduces manual review time and improves accuracy"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
            </div>

            <div className="flex justify-end">
              <button
                onClick={resetState}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50 flex justify-center items-center"
              >
                <RefreshCw size={18} />
                <span className="ml-2">Try Again</span>
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="relative py-32">
      <AnimatedBackground />
      {/* Hero Section with Video and Info Boxes */}
      <div className="max-w-5xl mx-auto mb-9">
        {/* Title and Video Row */}
        <div className="flex flex-col md:flex-row items-start justify-between gap-8 mb-6">
          <div className="flex-1">
            <h1 className="text-[32px] md:text-[40px] font-['Impact']  text-[#00D05E] mb-4">
              Welcome to Policy Extraction Module
            </h1>
            <h1 className="text-2xl font-semibold text-[#F93DCD] mb-2">For this demo, we are using industry standard
              policy wordings. You can provide us your own policy wordings for any line of business when you decide to go with us.
              This is purely for demonstration only.</h1>
            <p className="text-[#ACACAC] leading-relaxed mb-6">
              Let our AI assistant help you identify relevant policy sections quickly and accurately.<span className="text-lg text-[#F93DCD]"> Please play the video to understand how the demo works.</span>
            </p>
            {/* Info Boxes Below Title */}
            <div className="grid grid-cols-1 gap-6">
              <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
                <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                  <GitBranch />
                  <span className="ml-2">Smart Policy Analysis</span>
                </h2>
                <p className="leading-[160%] font-light text-[#ACACAC]">
                  The Policy Extraction module analyses incident descriptions to
                  automatically identify and extract relevant policy sections,
                  streamlining the claims review process by highlighting applicable
                  coverages and exclusions.
                </p>
              </section>

              <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
                <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                  <AlertTriangle />
                  <span className="ml-2">Current Challenges</span>
                </h2>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Manual policy review is time-consuming</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Risk of missing critical exclusions</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Inconsistent interpretation</span>
                </p>
              </section>
            </div>
          </div>

          {/* Video Avatar */}
          <div className="w-full md:w-[320px] shrink-0">
            <div className="aspect-[9/16] relative rounded-xl border border-gray-800 overflow-hidden bg-black/40">
              <div
                ref={videoContainerRef}
                className="w-full h-full z-10 relative pointer-events-auto"
                style={{
                  position: 'relative',
                  zIndex: 50
                }}
              />

              {/* Play Overlay */}
              {!isVideoPlaying && (
                <div
                  onClick={handlePlayVideo}
                  className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 cursor-pointer z-[60] transition-opacity hover:bg-black/40"
                >
                  <div className="w-16 h-16 rounded-full bg-[#00D05E]/20 flex items-center justify-center backdrop-blur-sm border border-[#00D05E]/30 mb-3">
                    <PlayCircle size={40} className="text-[#00D05E]" />
                  </div>
                  <p className="text-white text-center px-4">
                    Click me to understand how the demo works
                  </p>
                </div>
              )}
            </div>
            {/* Demo Button and Animation - Moved here */}
            <div className="mt-8 flex flex-col items-center">
              <div
                className="cursor-pointer mb-4"
                onClick={scrollToDemo}
              >
                <div className="flex flex-col items-center hover:scale-110 transition-transform">
                  <div className="text-[#00D05E] text-2xl font-bold mb-2">Try the Demo Below!</div>
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-[#00D05E]"
                  >
                    <path
                      d="M12 22L12 2M12 22L18 16M12 22L6 16"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="flex justify-center items-center">
      <TryDemoButton onClick={scrollToDemo} />
    </div> */}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mb-9">
          <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
            <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
              <CircuitBoard />
              <span className="ml-2">Technical Capabilities</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Languages />
                  <span className="ml-2">Natural Language</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Process incident descriptions in natural language
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ScanLine />
                  <span className="ml-2">Smart Analysis</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Contextual analysis for precise policy matching
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ListFilter />
                  <span className="ml-2">Automated Extraction</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Instantly identifies relevant policy sections
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Network />
                  <span className="ml-2">Coverage Analysis</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Comprehensive review of policy coverage
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ShieldCheck />
                  <span className="ml-2">Error Prevention</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Reduces oversight in policy review
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Scale />
                  <span className="ml-2">Consistent Review</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Ensures uniform policy interpretation
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <div className="hidden md:flex justify-center items-center mb-9">
        <TryDemoButton onClick={scrollToDemo} />
      </div> */}
      <section ref={demoRef}>
        <div className="max-w-4xl mx-auto text-center mb-9 pt-9">
          <h2 className="text-2xl font-bold text-[#00D05E] mb-2">
            POLICY EXTRACTION DEMO
          </h2>
          {currentStep === 1 && (
            <p className="leading-[160%] font-light text-[#ACACAC]">
              This demo shows how <span className="text-[#F93DCD]">Optium</span> automatically
              identifies and extracts relevant policy sections using just the <span className="text-xl text-[#F93DCD]">motor incident</span> description you entered.
              Please describe a motor incident below and click 'Extract Policy Information' to see Optium
              analyse the applicable policy sections for you.
            </p>
          )}
          {currentStep === 2 && (
            <p className="leading-[160%] font-light text-[#ACACAC]">
              Optium has analysed your description and extracted the relevant policy sections
            </p>
          )}
          {currentStep === 3 && (
            <p className="leading-[160%] font-light text-[#ACACAC]">
              What does this mean for you
            </p>
          )}
        </div>

        <div className="max-w-4xl mx-auto text-center mb-9">
          <ThreeStepProgress currentStep={currentStep} />
        </div>

        <div className="max-w-5xl mx-auto">
          <AnimatedBorder>
            <div className="bg-black backdrop-blur-sm rounded-3xl border border-gray-800 p-6 overflow-hidden">
              <AnimatedBackground />
              {renderStep()}
            </div>
          </AnimatedBorder>
        </div>
      </section>
    </div>
  );
};

export default PolicyExtraction;