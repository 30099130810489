/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
// src/pages/InconsistencyIdentification.js
import React, { useState, useRef, useEffect } from "react";
import { INCONSISTENCY_IDENTIFICATION_URL, INCONSISTENCY_IDENTIFICATION_PROMPTID, HEADER_CLIENT_KEY } from "./utils/apiManager";
import useAccessToken from './useAccessToken';
import { FastForward, PlayCircle } from 'lucide-react';
import Player from '@vimeo/player';
import {
  ScanSearch,
  Zap,
  Link,
  ShieldAlert,
  GitMerge,
  Filter,
  ChevronRight,
} from "lucide-react";
import {
  Lightbulb,
  CheckCircle2,
  AlertCircle,
  ShieldCheck,
} from "lucide-react";
import {
  AlertTriangle,
  CheckCircle,
  CircleHelp,
  CloudRainWind,
  Haze,
  RefreshCw,
  Snowflake,
  Sun,
  Wind,
} from "lucide-react";
import WeatherCard from "./components/WeatherCard";
import AnalyzingAnimation from "./components/AnalyzingAnimation";
import AnimatedBackground from "./components/AnimatedBackground";
import WeatherParticles from "./components/WeatherParticles";
import AnimatedBorder from "./components/AnimatedBorder";
import TryDemoButton from "./components/TryDemoButton";
import ThreeStepProgress from "./components/ThreeStepProgress";
import TypeWriter from "./components/TypeWriter";
import { decryptResponse } from './utils/decryption';

const WEATHER_SCENARIOS = [
  {
    id: "clear",
    label: "Clear and Sunny",
    description:
      "Clear skies with bright sunshine, temperature around 25°C, excellent visibility",
    icon: <Sun size={30} color="#00D05E" />,
    sampleClaim: "I skidded on black ice and hit the curb",
    bgClass: "weather-sun",
  },
  {
    id: "snow",
    label: "Snow Storm",
    description:
      "Heavy snowfall with accumulation, temperature -5°C, reduced visibility",
    icon: <Snowflake size={30} color="#00D05E" />,
    sampleClaim: "The road was completely dry but my car slid off the road",
    bgClass: "weather-snow",
  },
  {
    id: "rain",
    label: "Heavy Rain",
    description: "Continuous heavy rainfall, strong winds, poor visibility",
    icon: <CloudRainWind size={30} color="#00D05E" />,
    sampleClaim: "Visibility was perfect when I suddenly hit the car in front",
    bgClass: "weather-rain",
  },
  {
    id: "fog",
    label: "Dense Fog",
    description:
      "Thick fog coverage, humidity 95%, visibility under 100 meters",
    icon: <Haze size={30} color="#00D05E" />,
    sampleClaim: "I could clearly see the traffic light from 200 meters away",
    bgClass: "weather-fog",
  },
  {
    id: "wind",
    label: "Strong Winds",
    description: "Clear skies but extremely strong winds around 60mph",
    icon: <Wind size={30} color="#00D05E" />,
    sampleClaim: "My car was stationary and perfectly stable",
    bgClass: "weather-wind",
  },
];

const InconsistencyIdentification = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");
  const [selectedWeather, setSelectedWeather] = useState(null);
  const [aiOutput, setAiOutput] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const demoRef = useRef(null);
  const accessToken = useAccessToken();
  const resetState = () => {
    setCurrentStep(1);
    setDescription("");
    setSelectedWeather(null);
    setAiOutput("");
    setIsAnalyzing(false);
  };
  const videoContainerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let player = null;

    const initPlayer = async () => {
      if (videoContainerRef.current) {
        const options = {
          url: 'https://vimeo.com/1029824047/62f7b133db',
          autoplay: false,
          loop: false,
          controls: true,
          responsive: true,
          muted: false,
          title: false,
          byline: false,
          portrait: false
        };

        try {
          player = new Player(videoContainerRef.current, options);
          playerInstanceRef.current = player;

          await player.ready();
          await player.setVolume(1);
        } catch (error) {
          console.log("Player initialization error:", error);
        }
      }
    };

    initPlayer();

    return () => {
      if (player) {
        player.unload().catch(console.log);
        playerInstanceRef.current = null;
      }
    };
  }, []);

  const handlePlayVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play().then(() => {
        setIsVideoPlaying(true);
      }).catch(error => {
        //console.error("Error playing video:", error);
      });
    }
  };

  const fetchInconsistencies = async () => {
    setIsAnalyzing(true);
    try {
      //   const response = await fetch(INCONSISTENCY_IDENTIFICATION_URL, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
      //     },
      //     body: JSON.stringify({
      //       claim_data: {
      //         claim_narrative: description,
      //         weather_api_description: selectedWeather?.description || "",
      //       },
      //     }),
      //   });
      //   const data = await response.json();
      //   setAiOutput(
      //     data.Inconsistencies[0]?.Explanation || "No inconsistencies found."
      //   );
      const requestAiServiceBody = {
        promptId: INCONSISTENCY_IDENTIFICATION_PROMPTID,
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: JSON.stringify({
              claim_data: {
                claim_narrative: description,
                weather_api_description: selectedWeather?.description || "",
              },
            })
          }
        ]
      };
      const apiPromise = fetch(INCONSISTENCY_IDENTIFICATION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          // -> client key for AI Service
          "x-client-key": HEADER_CLIENT_KEY,
          // bearer token from auth0
          "Authorization": `Bearer ${accessToken}`
        },

        body: JSON.stringify(requestAiServiceBody),
      }
      ).then((res) => res.json());

      // Wait for both the API and the animation sequence
      const [data] = await Promise.all([
        apiPromise,
      ]);
      // console.log(data);
      const decryptedResponse = decryptResponse(data.result);

      let module_response = decryptedResponse;

      module_response = module_response.replace("```json\n", "").replace("\n```", "");

      module_response = JSON.parse(module_response);
      // console.log(module_response);
      setAiOutput(
        module_response.Inconsistencies[0]?.Explanation || "No inconsistencies found."
      );
    } catch (error) {
      //console.error("Error:", error);
    } finally {
      setTimeout(() => {
        setIsAnalyzing(false);
      }, 2000); // Keep animation visible for at least 2 seconds
    }
  };

  // useEffect(() => {
  //   if (description && selectedWeather) {
  //     const timeout = setTimeout(() => {
  //       fetchInconsistencies();
  //     }, 1000);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [description, selectedWeather]);

  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  // Modify the textarea onChange handler
  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    const wordCount = countWords(text);

    if (wordCount <= 250) {
      setDescription(text);
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            {/* Enhanced benefit message */}
            <div className="bg-black/20 backdrop-blur-sm rounded-2xl p-4 mb-6 border border-[#00D05E]/20">
              <div className="flex items-center gap-3">
                <div>
                  <Zap className="w-5 h-5 text-[#F93DCD]" />
                </div>
                <p className="text-[#ACACAC] leading-relaxed text-lg">
                  This module helps you identify inconsistencies within the claim data by checking it against external data sources ensuring you get consistent and logical responses.
                </p>
              </div>
            </div>
            {/* Weather Selection */}
            <div className="space-y-4">
              <h3 className="text-white mb-3">Select Weather Conditions</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {WEATHER_SCENARIOS.map((scenario) => (
                  <WeatherCard
                    key={scenario.id}
                    scenario={scenario}
                    isSelected={selectedWeather?.id === scenario.id}
                    onSelect={setSelectedWeather}
                  />
                ))}
              </div>
            </div>

            {/* Description Input */}
            <div className="space-y-4">
              <p className="text-white mb-3 flex justify-start items-center">
                <CircleHelp size={24} color="#00D05E" />
                <span className="ml-2">Describe your Incident</span>
              </p>
              <div className="relative">
                {selectedWeather && (
                  <WeatherParticles type={selectedWeather.id} />
                )}
                <textarea
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder={
                    selectedWeather
                      ? `Describe your incident 

Try to contradict the weather conditions above! For example, if you selected Sunny, you could put 'I skidded on ice and hit a tree' `
                      : "Please select weather conditions first"
                  }
                  disabled={!selectedWeather}
                  className="w-full h-48 bg-black/20 rounded-lg text-white focus:outline-none resize-none p-4 placeholder-gray-500 disabled:opacity-50"
                />
                <div className="text-sm text-gray-400 mt-2">
                  {countWords(description)}/250 words
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  fetchInconsistencies();
                  setCurrentStep(2);
                }}
                disabled={!description.trim() || !selectedWeather}
                className="bg-[#00D05E] text-white px-6 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50"
              >
                Analyse Inconsistencies
              </button>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            {/* Display selected weather and description */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <AnimatedBorder>
                <div className="bg-black/90 p-6 rounded-3xl h-full">
                  <h4 className="text-[#00D05E] text-lg mb-4 font-bold">
                    Weather Conditions
                  </h4>
                  <div className="flex items-center gap-4">
                    <div className="mr-1">{selectedWeather.icon}</div>
                    <div>
                      <p className="text-white font-semibold">
                        {selectedWeather.label}
                      </p>
                      <p className="text-gray-400 text-sm">
                        {selectedWeather.description}
                      </p>
                    </div>
                  </div>
                </div>
              </AnimatedBorder>

              <AnimatedBorder>
                <div className="bg-black/90 p-6 rounded-3xl h-full">
                  <h4 className="text-[#00D05E] text-lg mb-4 font-bold">
                    Your Description
                  </h4>
                  <p className="font-light text-[#ACACAC]">{description}</p>
                </div>
              </AnimatedBorder>
            </div>

            {/* AI Analysis Output */}
            <AnimatedBorder>
              <div className="bg-black/90 p-6 rounded-3xl h-full">
                <h4 className="text-[#00D05E] text-lg mb-4 flex items-center gap-2">
                  <AlertTriangle className="text-yellow-500" size={24} />
                  <span className="font-bold">Inconsistency Analysis</span>
                </h4>
                <div className="text-white">
                  {isAnalyzing ? (
                    <AnalyzingAnimation />
                  ) : (
                    <p className="text-base font-light text-[#ACACAC]">
                      {aiOutput}
                    </p>
                  )}
                </div>
              </div>
            </AnimatedBorder>

            <div className="flex justify-end">
              <button
                onClick={() => setCurrentStep(3)}
                className="bg-[#00D05E] text-white px-6 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
              >
                View Capabilities
              </button>
            </div>
          </div>
        );

      // Continuing renderStep in InconsistencyIdentification.js
      case 3:
        return (
          <div className="space-y-6 text-white">
            <div className="grid grid-cols-1 md:grid-cols- lg:grid-cols-3 gap-6">
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <ScanSearch className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Smart Verification" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Cross-verifies descriptions with external data and checks for illogical narratives"
                      delay={10}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <Zap className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Fast Processing" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="~3 seconds execution time for up to 4 features"
                      delay={20}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <Link className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Integration Ready" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Seamlessly connects with third-party verification services"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <ShieldAlert className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Fraud Detection" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Identifies potential fraud flags requiring further investigation"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <GitMerge className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Automated Triaging" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Works with other modules for comprehensive claims assessment"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <Filter className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="STP Control" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Enables rejection of claims for Straight Through Processing"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <AnimatedBorder extraClasses="transform hover:scale-[101%] transition-all duration-300">
                <div className="bg-black/90 p-6 rounded-3xl">
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-4">
                    Key Benefits
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex items-start gap-3">
                      <CheckCircle className="w-5 h-5 text-[#00D05E]" />
                      <span className="text-[#ACACAC]">
                        Real-time inconsistency detection
                      </span>
                    </div>
                    <div className="flex items-start gap-3">
                      <CheckCircle className="w-5 h-5 text-[#00D05E]" />
                      <span className="text-[#ACACAC]">
                        Reduced processing time and costs
                      </span>
                    </div>
                    <div className="flex items-start gap-3">
                      <CheckCircle className="w-5 h-5 text-[#00D05E]" />
                      <span className="text-[#ACACAC]">
                        Enhanced fraud prevention
                      </span>
                    </div>
                    <div className="flex items-start gap-3">
                      <CheckCircle className="w-5 h-5 text-[#00D05E]" />
                      <span className="text-[#ACACAC]">
                        Improved data accuracy
                      </span>
                    </div>
                  </div>
                </div>
              </AnimatedBorder>
            </div>

            <div className="flex justify-end">
              <button
                onClick={resetState}
                disabled={!description.trim()}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50 flex justify-center items-center"
              >
                <RefreshCw size={18} />
                <span className="ml-2">Try Again</span>
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="relative py-32">
      <AnimatedBackground />
      {/* Hero Section with Video and Info Boxes */}
      <div className="max-w-5xl mx-auto mb-9">
        {/* Title and Video Row */}
        <div className="flex flex-col md:flex-row items-start justify-between gap-8 mb-6">
          <div className="flex-1">
            <h1 className="text-[32px] font-['Impact'] md:text-[40px] text-[#00D05E] mb-4">
              Welcome to Inconsistency Identification Module
            </h1>
            <h1 className="text-2xl font-semibold text-[#F93DCD] mb-2">For this demo, we have selected weather data to identify inconsistencies.
              Our module can check against many other external data sources as you require when you decide to go with us.
              This is purely for demonstration only.</h1>
            <p className="text-[#ACACAC] leading-relaxed mb-6">
              Let our AI assistant help ensure your incident descriptions are consistent with external data.<span className="text-lg text-[#F93DCD]"> Please play the video to understand how the demo works.</span>
            </p>
            {/* Info Boxes Below Title */}
            <div className="grid grid-cols-1 gap-6">
              <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
                <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                  <ScanSearch />
                  <span className="ml-2">Smart Cross-Referencing System</span>
                </h2>
                <p className="leading-[160%] font-light text-[#ACACAC]">
                  Optium's Inconsistency Module helps claimants ensure
                  that their incident descriptions are consistent and logical by
                  cross-referencing their responses with external data sources.
                </p>
              </section>

              <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
                <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                  <AlertTriangle />
                  <span className="ml-2">Current Challenges</span>
                </h2>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Inconsistent information provided by claimants</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Manual verification process causing delays</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Increased processing costs</span>
                </p>
                <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                  <ChevronRight size={14} />
                  <span className="ml-2">Potential fraud risks</span>
                </p>
              </section>
            </div>
          </div>

          {/* Video Avatar */}
          <div className="w-full md:w-[320px] shrink-0">
            <div className="aspect-[9/16] relative rounded-xl border border-gray-800 overflow-hidden bg-black/40">
              <div
                ref={videoContainerRef}
                className="w-full h-full z-10 relative pointer-events-auto"
                style={{
                  position: 'relative',
                  zIndex: 50
                }}
              />

              {/* Play Overlay */}
              {!isVideoPlaying && (
                <div
                  onClick={handlePlayVideo}
                  className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 cursor-pointer z-[60] transition-opacity hover:bg-black/40"
                >
                  <div className="w-16 h-16 rounded-full bg-[#00D05E]/20 flex items-center justify-center backdrop-blur-sm border border-[#00D05E]/30 mb-3">
                    <PlayCircle size={40} className="text-[#00D05E]" />
                  </div>
                  <p className="text-white text-center px-4">
                    Click me to understand how the demo works
                  </p>
                </div>
              )}
            </div>
            {/* Demo Button and Animation - Moved here */}
            <div className="mt-8 flex flex-col items-center">
              <div
                className="cursor-pointer mb-4"
                onClick={scrollToDemo}
              >
                <div className="flex flex-col items-center hover:scale-110 transition-transform">
                  <div className="text-[#00D05E] text-2xl font-bold mb-2">Try the Demo Below!</div>
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-[#00D05E]"
                  >
                    <path
                      d="M12 22L12 2M12 22L18 16M12 22L6 16"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="flex justify-center items-center">
      <TryDemoButton onClick={scrollToDemo} />
    </div> */}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mb-9">
          <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
            <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
              <Lightbulb />
              <span className="ml-2">Technical Solution</span>
            </h2>
            <p className="leading-[160%] font-light text-[#ACACAC] mb-3">
              The module cross-verifies the incident description entered by the
              claimant against third-party data sources, highlighting
              inconsistencies during the eFNOL phase.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <CheckCircle2 />
                  <span className="ml-2">External Verification</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Cross-references with third-party data
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <AlertCircle />
                  <span className="ml-2">Real-time Detection</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Immediate inconsistency identification
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <ShieldCheck />
                  <span className="ml-2">Fraud Prevention</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Early detection of potential issues
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <div className="hidden md:flex justify-center items-center mb-9">
        <TryDemoButton onClick={scrollToDemo} />
      </div> */}
      <section ref={demoRef}>
        {/* Third Section */}
        <div className="max-w-4xl mx-auto text-center mb-9 pt-9">
          <h2 className="text-2xl font-bold text-[#00D05E] mb-2">
            INCONSISTENCY IDENTIFICATION DEMO
          </h2>
          <p className="leading-[160%] font-light text-[#ACACAC]">
            Select a weather scenario and provide a claim description that might
            contradict it. {" "}
            <span className="text-[#F93DCD]">Optium</span> will analyse the
            consistency between your description and the weather conditions.
          </p>
        </div>
        {/* Forth Section */}
        <div className="max-w-4xl mx-auto text-center mb-9">
          <ThreeStepProgress currentStep={currentStep} />
        </div>
        {/* Fifth Section */}
        <div className="max-w-5xl mx-auto">
          <AnimatedBorder>
            <div className="bg-black backdrop-blur-sm rounded-3xl border border-gray-800 p-6 overflow-hidden">
              {/* Content Card */}
              <AnimatedBackground />
              {renderStep()}
            </div>
          </AnimatedBorder>
        </div>
      </section>
    </div>
  );
};

export default InconsistencyIdentification;
