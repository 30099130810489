import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./MainLayout";
import GuidedFreeText from "./GuidedFreeText";
import HierarchySelection from "./HierarchySelection";
import InconsistencyIdentification from "./InconsistencyIdentification";
import HealthCheck from './HealthCheck';
import PolicyExtraction from "./PolicyExtraction";
import CEOMessage from "./CEOMessage";
import Dashboard from "./Dashboard";
import PolicyValidation from "./PolicyValidation";
import CauseSubCauseSelection from "./CauseSubCauseSelection";
import HomePage from "./HomePage";
import ProtectedRoute from "./components/ProtectedRoute";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function App() {
  const { isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) {
    return null;
  }

  return (
    <Router>
      <Routes>
        {/* Health Check Route - Unauthenticated */}
        <Route path="/health" element={<HealthCheck />} />
        
        <Route 
          path="/welcome" 
          element={
            isAuthenticated ? 
              <CEOMessage /> : 
              <Navigate to="/" replace />
          } 
        />

        <Route 
          path="/" 
          index
          element={
            isAuthenticated ? 
              <Navigate to="/welcome" replace /> : 
              <HomePage />
          } 
        />
        
        {/* Protected Dashboard Route */}
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? 
              <MainLayout /> : 
              <Navigate to="/" replace />
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="category-selection" element={<HierarchySelection />} />
          <Route path="guided-text" element={<GuidedFreeText />} />
          <Route path="inconsistency" element={<InconsistencyIdentification />} />
          <Route path="policy-extraction" element={<PolicyExtraction />} />
          <Route path="policy-validation" element={<PolicyValidation />} />
          <Route path="cause-sub-cause-selection" element={<CauseSubCauseSelection />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;