// src/utils/liabilityMap.js
export const LIABILITY_MAP = {
    // Lane Change Collision
    "Insured failed to check blind spot": "Fault",
    "Unexpected lane change or turn by third party vehicle": "Non-Fault",
    "Unexpected lane change or turn by the insurer": "Fault",
    "Both vehicles failed to notice each other and changed lanes": "Partial",
    
    // Overtaking Incident
    "Third party vehicle overtook multiple vehicles and collided with insured": "Non-Fault",
    "Insured overtook multiple vehicles and collided with third party": "Fault",
    "Third party vehicle pulled into oncoming traffic and swerved into insured": "Non-Fault",
    "Insured pulled into oncoming traffic and swerved into third party": "Fault",
    "Third party misjudged overtaking and collided with insured": "Non-Fault",
    "Insured misjudged overtaking and collided with third party": "Fault",
  
    // Intersection Collision
    "Collision at a four-way stop": "Partial",
    "Accident at a traffic light intersection": "Partial",
    "Insured merged from side street into oncoming traffic": "Fault",
    "Third party failed to yield at intersection": "Non-Fault",
    "Third party ran a red light": "Non-Fault",
    "Insured misjudged gap at intersection": "Fault",
  
    // Head-On Collision
    "Collision on a narrow road": "Partial",
    "Insured crossed center line": "Fault",
    "Third party turned into oncoming traffic": "Non-Fault",
    "Third party lost control and veered into insured's lane": "Non-Fault",
  
    // Reversing Accident
    "Insured reversed into stationary object": "Fault",
    "Both vehicles reversed simultaneously": "Partial",
    "Third party reversed into path of insured": "Non-Fault",
    "Insured failed to notice third party while reversing": "Fault",
  
    // Roundabout Incident
    "Insured failed to yield on roundabout": "Fault",
    "Vehicle changed lanes within roundabout": "Partial",
    "Collision while exiting roundabout": "Partial",
    "Third party entered roundabout without yielding": "Non-Fault",
    "Insured misjudged roundabout entry": "Fault",
  
    // Open Door Collision
    "Insured opened door into path of cyclist": "Fault",
    "Door hit by passing vehicle": "Partial",
    "Third party opened door into traffic": "Partial",
    "Insured's door hit by third party vehicle": "Partial",
  
    // Parking Lot Collision
    "Insured vehicle hit while parked": "Non-Fault",
    "Collision while exiting parking space": "Partial",
    "Insured hit parked vehicle": "Fault",
    "Third party vehicle hit while reversing": "Non-Fault",
    "Insured misjudged parking maneuver": "Fault",
  
    // Rear-End Collision
    "Insured hit vehicle in front": "Fault",
    "Vehicle hit from behind while stationary": "Non-Fault",
    "Chain reaction collision": "Partial",
    "Third party vehicle hit insured from behind": "Non-Fault",
    "Insured braked suddenly causing collision": "Non-Fault",
  
    // Insurer Vehicle Damage Only
    "Collided with a (post/wall/curb/bollard/fixed object)": "Fault",
    "Swerved to avoid an animal": "Fault",
    "Damage from pothole": "Fault",
    "Vehicle struck by falling debris": "Fault",
    "Damage from road hazard": "Fault",
    "Third party debris caused damage": "Fault",
    "Insured swerved to avoid obstacle": "Fault",
    "Insured vehicle stationary during police blockade, hit by fleeing vehicle": "Non-Fault",
  
    // Cyclist or Pedestrian Collision
    "Collision with cyclist at intersection": "Fault",
    "Pedestrian struck while crossing road": "Fault",
    "Cyclist hit while overtaking": "Fault",
    "Third party vehicle hit pedestrian": "Non-Fault",
    "Insured failed to notice pedestrian": "Fault",
  
    // Mechanical or Electrical Failure
    "Engine failure": "Fault",
    "Brake malfunction": "Fault",
    "Electrical system failure": "Fault",
    "Third party vehicle breakdown caused obstruction": "Partial",
    "Insured vehicle malfunctioned unexpectedly": "Fault",
  
    // Vehicle Fire
    "Electrical fault": "Fault",
    "Engine overheating": "Fault",
    "Fire from external source": "Fault",
    "Third party vehicle fire spread": "Non-Fault",
    "Insured's vehicle caught fire due to defect": "Fault",
  
    // Property Fire
    "Fire damage from nearby building": "Non-Fault",
    "Fire damage from adjacent vehicle": "Non-Fault",
    "Third party property fire spread": "Non-Fault",
    "Insured's property caught fire": "Fault",
  
    // Flood Damage
    "Vehicle submerged in floodwater": "Fault",
    "Water damage from heavy rain": "Fault",
    "Saltwater exposure": "Fault",
    "Third party vehicle caused water splash": "Non-Fault",
    "Insured drove into flooded area": "Fault",
  
    // Intentional Damage
    "Graffiti on vehicle": "Fault",
    "Windows smashed": "Fault",
    "Tires slashed": "Fault",
    "Third party witnessed vandalism": "Non-Fault",
    "Insured reported vandalism": "Fault",
  
    // Riot Damage
    "Damage from protest": "Fault",
    "Vehicle vandalized during civil commotion": "Fault",
    "Third party involvement in riot": "Fault",
    "Insured's vehicle targeted during unrest": "Fault",
  
    // Incorrect Fuel
    "Diesel in petrol engine": "Fault",
    "Petrol in diesel engine": "Fault",
    "Third party refueled vehicle incorrectly": "Non-Fault",
    "Insured mistakenly refueled with wrong type": "Fault",
  
    // Snow and Ice
    "Loss of control on icy road": "Fault",
    "Snow slide damage": "Fault",
    "Third party vehicle skidded on ice": "Non-Fault",
    "Insured lost control due to snow": "Fault",
  
    // Storm Damage
    "Tree branch fell on vehicle": "Fault",
    "Hail damage": "Fault",
    "Third party property debris caused damage": "Non-Fault",
    "Insured's vehicle damaged by storm": "Fault",
  
    // Vehicle Theft
    "Stolen from driveway": "Fault",
    "Stolen from public parking": "Fault",
    "Recovered after theft": "Fault",
    "Third party witnessed theft": "Fault",
    "Insured reported vehicle stolen": "Fault",
  
    // Theft from Vehicle
    "Personal items stolen": "Fault",
    "Audio equipment stolen": "Fault",
    "Parts stolen from vehicle": "Fault",
    "Third party reported suspicious activity": "Fault",
    "Insured discovered theft": "Fault",
  
    // Attempted Theft
    "Damage from attempted break-in": "Fault",
    "Lock tampered with": "Fault",
    "Third party interrupted theft attempt": "Fault",
    "Insured thwarted theft attempt": "Fault",
  
    // Windscreen Repair/Replacement
    "Minor chip repair": "Fault",
    "Crack repair": "Fault",
    "Third party debris caused chip": "Non-Fault",
    "Insured noticed windscreen damage": "Fault",
    "Severe crack": "Fault",
    "Shattered windscreen": "Fault",
    "Third party vehicle caused damage": "Non-Fault",
    "Insured's windscreen damaged by debris": "Fault"
  };
  
  export const LIABILITY_COLORS = {
    "Fault": "text-[#F93DCD]",
    "Non-Fault": "text-[#00D05E]",
    "Partial": "text-[#FFD700]",
    "Partial": "text-[#ACACAC]"
  };