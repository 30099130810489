export const GUIDELINES = {
  GUIDED_FREE_TEXT: {
    guideline_motor: "Describe what happened, noting how, Approximate Location (for example, street name, etc.). Comment on the area of impact on the vehicle and specific damage caused. Include information about any other vehicles or property involved. ONLY IF there is any other vehicle, comment on the direction of their travel in relation to your vehicle (i.e. coming from behind/opposite direction and making a left/right turn, parking/parked, moving forward/reversing).",
    guideline_home: "Describe what happened, specifying the area of the home affected (e.g., kitchen, backyard). Mention any damage to the property. Mention any actions taken to address the situation (For example, calling for repairs, contacting emergency services, and include any relevant causes, like weather conditions, etc.).",
    guideline_pet: "Describe what happened, including the pet's actions and the approximate location (e.g., backyard, park). Note any injuries or distress to the pet and visible signs of harm. ONLY if other animals or people were involved, describe what they did. Mention any actions taken (For example, visiting a vet, contacting authorities, etc.)"
  }
};

export const POLICYHOLDER_SCHEDULE = {
  MOTOR: {
    schedule: `Coverage Details: \n Liability Limits: Bodily Injury £100,000/£300,000, Property Damage £50,000 \n Collision Deductible: £500 \n Comprehensive Deductible: £300 \n Additional Coverages: \n - Motor Legal Assistance \n \n Premium Summary: \n Total Annual Premium: £850 \n Monthly Installment: £70.83`
  }
};

export const MOTOR_CATEGORIES = {
  "Accident": {
    "Lane Change Collision": [
      "Insured failed to check blind spot",
      "Unexpected lane change or turn by third party vehicle",
      "Unexpected lane change or turn by the insurer",
      "Both vehicles failed to notice each other and changed lanes"
    ],
    "Overtaking Incident": [
      "Third party vehicle overtook multiple vehicles and collided with insured",
      "Insured overtook multiple vehicles and collided with third party",
      "Third party vehicle pulled into oncoming traffic and swerved into insured",
      "Insured pulled into oncoming traffic and swerved into third party",
      "Third party misjudged overtaking and collided with insured",
      "Insured misjudged overtaking and collided with third party"
    ],
    "Intersection Collision": [
      "Collision at a four-way stop",
      "Accident at a traffic light intersection",
      "Insured merged from side street into oncoming traffic",
      "Third party failed to yield at intersection",
      "Third party ran a red light",
      "Insured misjudged gap at intersection"
    ],
    "Head-On Collision": [
      "Collision on a narrow road",
      "Insured crossed center line",
      "Third party turned into oncoming traffic",
      "Third party lost control and veered into insured's lane"
    ],
    "Reversing Accident": [
      "Insured reversed into stationary object",
      "Both vehicles reversed simultaneously",
      "Third party reversed into path of insured",
      "Insured failed to notice third party while reversing"
    ],
    "Roundabout Incident": [
      "Insured failed to yield on roundabout",
      "Vehicle changed lanes within roundabout",
      "Collision while exiting roundabout",
      "Third party entered roundabout without yielding",
      "Insured misjudged roundabout entry"
    ],
    "Open Door Collision": [
      "Insured opened door into path of cyclist",
      "Door hit by passing vehicle",
      "Third party opened door into traffic",
      "Insured's door hit by third party vehicle"
    ],
    "Parking Lot Collision": [
      "Insured vehicle hit while parked",
      "Collision while exiting parking space",
      "Insured hit parked vehicle",
      "Third party vehicle hit while reversing",
      "Insured misjudged parking maneuver"
    ],
    "Rear-End Collision": [
      "Insured hit vehicle in front",
      "Vehicle hit from behind while stationary",
      "Chain reaction collision",
      "Third party vehicle hit insured from behind",
      "Insured braked suddenly causing collision"
    ],
    "Insurer Vehicle Damage Only": [
      "Collided with a (post/wall/curb/bollard/fixed object)",
      "Swerved to avoid an animal",
      "Damage from pothole",
      "Damage from pothole",
      "Damage from pothole",
      "Vehicle struck by falling debris",
      "Damage from road hazard",
      "Third party debris caused damage",
      "Insured swerved to avoid obstacle",
      "Insured vehicle stationary during police blockade, hit by fleeing vehicle"
    ],
    "Cyclist or Pedestrian Collision": [
      "Collision with cyclist at intersection",
      "Pedestrian struck while crossing road",
      "Cyclist hit while overtaking",
      "Third party vehicle hit pedestrian",
      "Insured failed to notice pedestrian"
    ],
    "Mechanical or Electrical Failure": [
      "Engine failure",
      "Brake malfunction",
      "Electrical system failure",
      "Third party vehicle breakdown caused obstruction",
      "Insured vehicle malfunctioned unexpectedly"
    ],
  },
  "Fire": {
    "Vehicle Fire": [
      "Electrical fault",
      "Engine overheating",
      "Fire from external source",
      "Third party vehicle fire spread",
      "Insured's vehicle caught fire due to defect"
    ],
    "Property Fire": [
      "Fire damage from nearby building",
      "Fire damage from adjacent vehicle",
      "Third party property fire spread",
      "Insured's property caught fire"
    ]
  },
  "Water Damage": {
    "Flood Damage": [
      "Vehicle submerged in floodwater",
      "Water damage from heavy rain",
      "Saltwater exposure",
      "Third party vehicle caused water splash",
      "Insured drove into flooded area"
    ]
  },
  "Vandalism": {
    "Intentional Damage": [
      "Graffiti on vehicle",
      "Windows smashed",
      "Tires slashed",
      "Third party witnessed vandalism",
      "Insured reported vandalism"
    ]
  },
  "Civil Unrest": {
    "Riot Damage": [
      "Damage from protest",
      "Vehicle vandalised during civil commotion",
      "Third party involvement in riot",
      "Insured's vehicle targeted during unrest"
    ]
  },
  "Fuel Error": {
    "Incorrect Fuel": [
      "Diesel in petrol engine",
      "Petrol in diesel engine",
      "Third party refueled vehicle incorrectly",
      "Insured mistakenly refueled with wrong type"
    ]
  },
  "Weather-Related Damage": {
    "Snow and Ice": [
      "Loss of control on icy road",
      "Snow slide damage",
      "Third party vehicle skidded on ice",
      "Insured lost control due to snow"
    ],
    "Storm Damage": [
      "Tree branch fell on vehicle",
      "Hail damage",
      "Third party property debris caused damage",
      "Insured's vehicle damaged by storm"
    ]
  },
  "Theft": {
    "Vehicle Theft": [
      "Stolen from driveway",
      "Stolen from public parking",
      "Recovered after theft",
      "Third party witnessed theft",
      "Insured reported vehicle stolen"
    ],
    "Theft from Vehicle": [
      "Personal items stolen",
      "Audio equipment stolen",
      "Parts stolen from vehicle",
      "Third party reported suspicious activity",
      "Insured discovered theft"
    ],
    "Attempted Theft": [
      "Damage from attempted break-in",
      "Lock tampered with",
      "Third party interrupted theft attempt",
      "Insured thwarted theft attempt"
    ]
  },
  "Glass Damage": {
    "Windscreen Repair": [
      "Minor chip repair",
      "Crack repair",
      "Third party debris caused chip",
      "Insured noticed windscreen damage"
    ],
    "Windscreen Replacement": [
      "Severe crack",
      "Shattered windscreen",
      "Third party vehicle caused damage",
      "Insured's windscreen damaged by debris"
    ]
  },
  "Unlisted Incident": {
    " Unlisted Incident ": [
      "Unlisted Incident"
    ]
  }
};

export const INJURY_CATEGORIES = {
  "Head and Neck": {
    "Head Injury": [
      "Concussion",
      "Skull Fracture",
      "Facial Injury",
      "Laceration"
    ],
    "Neck and Shoulder Strain": [
      "Whiplash",
      "Muscle Strain",
      "Cervical Sprain"
    ]
  },
  "Upper Body": {
    "Shoulder and Chest Injury": [
      "Dislocation",
      "Fracture",
      "Bruising"
    ],
    "Back and Sternum Injury": [
      "Back Strain",
      "Sternum Fracture",
      "Rotator Cuff Tear"
    ]
  },
  "Arms and Hands": {
    "Wrist and Hand Injury": [
      "Fracture",
      "Sprain",
      "Laceration"
    ],
    "Elbow and Forearm Injury": [
      "Sprain",
      "Contusion",
      "Tendon Injury"
    ]
  },
  "Lower Body": {
    "Hip and Thigh Injury": [
      "Fracture",
      "Bruising",
      "Muscle Tear"
    ],
    "Knee and Ankle Injury": [
      "Ligament Tear",
      "Sprain",
      "Fracture"
    ]
  },
  "Internal Injuries": {
    "Organ Contusion": [
      "Lung",
      "Kidney",
      "Liver"
    ],
    "Internal Bleeding": [
      "Minor",
      "Severe",
      "Hemoperitoneum"
    ]
  },
  "Sensory Impairments": {
    "Vision Impairment": [
      "Partial Loss",
      "Double Vision",
      "Photophobia"
    ],
    "Hearing Impairment": [
      "Temporary Loss",
      "Tinnitus",
      "Hyperacusis"
    ]
  },
  "Psychological Effects": {
    "Stress Reaction": [
      "Acute",
      "Anxiety",
      "PTSD"
    ],
    "Mood and Sleep Disturbance": [
      "Depression",
      "Sleep Disturbance",
      "Mood Swings"
    ]
  },
  "Chronic Conditions": {
    "Pain and Fatigue": [
      "Chronic Pain",
      "Fibromyalgia",
      "Chronic Fatigue"
    ],
    "Joint and Bone Conditions": [
      "Arthritis",
      "Osteoporosis",
      "Degenerative Disc Disease"
    ]
  },
  "Spinal Injuries": {
    "Disc and Nerve Injury": [
      "Herniated Disc",
      "Nerve Compression",
      "Spinal Cord Injury"
    ],
    "Spinal Fracture": [
      "Compression",
      "Burst",
      "Dislocation"
    ]
  },
  "Fatal Injuries": {
    "Immediate Fatality": [
      "Traumatic Brain Injury",
      "Massive Hemorrhage",
      "Cardiac Arrest"
    ],
    "Delayed Fatality": [
      "Sepsis",
      "Organ Failure",
      "Pulmonary Embolism"
    ]
  },
  "Burns and Scalds": {
    "Burn Severity": [
      "First-Degree",
      "Second-Degree",
      "Third-Degree"
    ],
    "Burn Type": [
      "Chemical",
      "Electrical",
      "Friction"
    ]
  },
  "Environmental Injuries": {
    "Temperature-Related": [
      "Frostbite",
      "Heat Exhaustion",
      "Hypothermia"
    ],
    "Other Environmental": [
      "Drowning",
      "Lightning Strike",
      "Animal Bite"
    ]
  },
  "Unlisted Incident": {
    "Unlisted Incident": [
      " Unlisted Incident "
    ]
  }

};

export const PET_CATEGORIES = {
  "Accident": {
    "Injury": [
      "Hit by vehicle",
      "Fall from height",
      "Injury from another animal",
      "Injury from sharp object",
      "Injury during play",
      "Injury from broken glass",
      "Injury from household tools",
      "Burns",
      "Electrocution"
    ],
    "Poisoning": [
      "Ingestion of toxic plants",
      "Ingestion of household chemicals",
      "Ingestion of human medication",
      "Ingestion of spoiled food",
      "Ingestion of chocolate",
      "Ingestion of antifreeze"
    ],
    "Foreign Object Ingestion": [
      "Swallowed toy",
      "Swallowed clothing",
      "Swallowed bone"
    ]
  },
  "Illness": {
    "Common Illnesses": [
      "Gastrointestinal issues",
      "Respiratory infections",
      "Skin conditions",
      "Ear infections",
      "Urinary tract infections"
    ],
    "Chronic Conditions": [
      "Diabetes",
      "Arthritis",
      "Heart disease",
      "Kidney disease",
      "Thyroid disorders",
      "Obesity"
    ],
    "Genetic Conditions": [
      "Hip dysplasia",
      "Epilepsy",
      "Allergies",
      "Progressive retinal atrophy"
    ],
    "Infectious Diseases": [
      "Parvovirus",
      "Feline leukemia",
      "Lyme disease",
      "Heartworm"
    ]
  },
  "Preventive Care": {
    "Vaccinations": [
      "Rabies",
      "Distemper",
      "Parvovirus",
      "Feline leukemia",
      "Bordetella"
    ],
    "Routine Check-ups": [
      "Annual wellness exam",
      "Dental cleaning",
      "Flea and tick prevention",
      "Heartworm prevention",
      "Spaying/neutering",
      "Microchipping"
    ],
    "Diet and Nutrition": [
      "Weight management",
      "Special dietary needs"
    ]
  },
  "Behavioral Issues": {
    "Training and Therapy": [
      "Aggression",
      "Separation anxiety",
      "Destructive behavior",
      "Excessive barking or meowing",
      "House soiling",
      "Fear-related behaviors"
    ]
  },
  "Liability": {
    "Third-Party Injury": [
      "Bite incident",
      "Scratch incident",
      "Knocking over a person",
      "Causing a cyclist to fall"
    ],
    "Third-Party Property Damage": [
      "Damage to neighbor's property",
      "Damage to public property",
      "Damage to another pet"
    ]
  },
  "Loss or Theft": {
    "Missing Pet": [
      "Lost during walk",
      "Escaped from home",
      "Stolen from property",
      "Lost during travel",
      "Failure to return home"
    ]
  },
  "Death": {
    "Euthanasia": [
      "Due to terminal illness",
      "Due to severe injury",
      "Due to old age"
    ],
    "Accidental Death": [
      "Hit by vehicle",
      "Severe injury from another animal",
      "Drowning"
    ],
    "Natural causes": []
  },
  "Boarding and Kennel Fees": {
    "Owner Hospitalisation": [
      "Boarding due to owner's illness",
      "Boarding due to owner's injury"
    ],
    "Travel": [
      "Boarding during owner's vacation"
    ],
    "Emergency boarding": []
  },
  "Unlisted Incident": {
    "Unlisted Incident": [
      "Unlisted Incident"
    ]
  }
};

export const HOME_CATEGORIES = {
  "Fire": {
    "Fire Damage": [
      "Kitchen fire - cooking accident",
      "Electrical fire - faulty wiring",
      "Fireplace or chimney fire",
      "Candle left unattended",
      "Arson attack",
      "Lightning strike",
      "Barbecue or outdoor fire pit"
    ],
    "Smoke Damage": [
      "Smoke from kitchen fire",
      "Smoke from electrical fire",
      "Smoke from neighboring property fire",
      "Smoke from wildfire"
    ]
  },
  "Water Damage": {
    "Flood": [
      "River or stream overflow",
      "Heavy rain causing flooding",
      "Sewage backup",
      "Storm surge"
    ],
    "Plumbing Issues": [
      "Burst pipe",
      "Leaking roof",
      "Overflowing bathtub or sink",
      "Faulty water heater",
      "Washing machine overflow",
      "Dishwasher leak"
    ],
    "Appliance Malfunction": [
      "Refrigerator leak",
      "Air conditioning unit leak"
    ]
  },
  "Theft": {
    "Burglary": [
      "Forced entry through door",
      "Forced entry through window",
      "Unforced entry - unlocked door",
      "Unforced entry - unlocked window",
      "Theft of outdoor property",
      "Posing as service personnel",
      "Distraction theft"
    ],
    "Vandalism": [
      "Graffiti on exterior walls",
      "Broken windows",
      "Damaged doors or locks",
      "Damage to garden or landscaping"
    ]
  },
  "Weather Damage": {
    "Storm": [
      "Roof damage from high winds",
      "Broken windows from hail",
      "Fallen trees or branches",
      "Damage from tornado"
    ],
    "Snow and Ice": [
      "Roof collapse from snow weight",
      "Ice damming causing leaks",
      "Frozen pipes",
      "Slippery walkways causing injury"
    ]
  },
  "Accidental Damage": {
    "Interior": [
      "Spilled paint on carpet",
      "Broken glass or ceramics",
      "Damaged furniture",
      "Accidental fire from cooking"
    ],
    "Exterior": [
      "Damage from lawn equipment",
      "Accidental damage to fences or gates",
      "Damage to driveway or walkway"
    ]
  },
  "Liability": {
    "Personal Liability": [
      "Injury to guest on property",
      "Damage to neighbor's property",
      "Injury from pet"
    ],
    "Pet Liability": [
      "Dog bite incident",
      "Pet causing damage to property",
      "Pet causing traffic accident"
    ]
  },
  "Loss of Use": {
    "Temporary Relocation": [
      "Due to fire damage",
      "Due to water damage",
      "Due to structural damage",
      "Due to mold remediation"
    ]
  },
  "Malicious Damage": {
    "Vandalism": [
      "Graffiti",
      "Broken windows",
      "Damaged doors",
      "Damage to vehicles on property"
    ]
  },
  "Electrical Damage": {
    "Power Surge": [
      "Damage to appliances",
      "Damage to electronics",
      "Damage to HVAC system"
    ]
  },
  "Pest Infestation": {
    "Termite Damage": [
      "Structural damage",
      "Damage to furniture"
    ],
    "Rodent Infestation": [
      "Chewed wiring",
      "Damage to insulation"
    ]
  },
  "Unlisted Incident": {
    "Unlisted Incident": [
      "Unlisted Incident"
    ]
  }
};